import {Injectable, NgZone, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {LoggerService} from '../logger.service';
import {Order, OrderApi, RealTime} from '../../sdk';
import {BehaviorSubject} from 'rxjs';
import {EventQueueService} from '../event-queue/event-queue.service';
import {AppEvent} from '../event-queue/app.event.class';
import {AppEventType} from '../event-queue/app.event.type';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private authService: AuthenticationService,
              private eventQueue: EventQueueService,
              private zone: NgZone,
              private orderApi: OrderApi,
              private realtimeService: RealTime,
              private logger: LoggerService) {
  }

  connectToRealtime(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.realtimeService.connection.isConnected()) {
        this.realtimeService.connection.connect(this.authService.loggedInUser.value.token);
        resolve(true);
        return;
      }
      resolve(true);
    });
  }


  receivedMessages: Array<any> = [];

  subscribeToData(): void {
    this.logger.log('subscribing to data ... ');

  }

  async loadOrder(id: any): Promise<any> {
    try {
      console.log('xxd', id);
      let order: Order = await this.orderApi.findOne<Order>({where: {id: id}}).toPromise();
      if (!(order && order.id)) {
        return Promise.reject('Order Not Found!');
      }
      return Promise.resolve(order);
    } catch (e) {
      this.logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }
}

/* tslint:disable */
import {
  Vendor,
  StockMovement
} from '../index';

declare var Object: any;
export interface StockInterface {
  "name": string;
  "code": string;
  "vendorId"?: any;
  "externalId"?: string;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "stockBalance": number;
  "salesStoreBalance"?: number;
  "categoryId"?: string;
  "categoryName"?: string;
  "balances"?: Array<any>;
  "uom"?: string;
  "threshold": number;
  "status"?: number;
  "id"?: number;
  vendor?: Vendor;
  stockMovement?: StockMovement[];
}

export class Stock implements StockInterface {
  "name": string;
  "code": string;
  "vendorId": any;
  "externalId": string;
  "updatedAt": Date;
  "updatedBy": any;
  "stockBalance": number;
  "salesStoreBalance": number;
  "categoryId": string;
  "categoryName": string;
  "balances": Array<any>;
  "uom": string;
  "threshold": number;
  "status": number;
  "id": number;
  vendor: Vendor;
  stockMovement: StockMovement[];
  constructor(data?: StockInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Stock`.
   */
  public static getModelName() {
    return "Stock";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Stock for dynamic purposes.
  **/
  public static factory(data: StockInterface): Stock{
    return new Stock(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Stock',
      plural: 'stock',
      path: 'stock',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "externalId": {
          name: 'externalId',
          type: 'string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "stockBalance": {
          name: 'stockBalance',
          type: 'number',
          default: 0
        },
        "salesStoreBalance": {
          name: 'salesStoreBalance',
          type: 'number',
          default: 0
        },
        "categoryId": {
          name: 'categoryId',
          type: 'string'
        },
        "categoryName": {
          name: 'categoryName',
          type: 'string'
        },
        "balances": {
          name: 'balances',
          type: 'Array&lt;any&gt;'
        },
        "uom": {
          name: 'uom',
          type: 'string',
          default: 'units'
        },
        "threshold": {
          name: 'threshold',
          type: 'number',
          default: 0
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        stockMovement: {
          name: 'stockMovement',
          type: 'StockMovement[]',
          model: 'StockMovement',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'id'
        },
      }
    }
  }
}

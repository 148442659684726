/* tslint:disable */
import {
  Vendor,
  Order,
  CouponRecord
} from '../index';

declare var Object: any;
export interface CouponInterface {
  "vendorId": any;
  "name": string;
  "code": string;
  "minOrderTotal": number;
  "count": number;
  "redeemed": number;
  "used"?: number;
  "startDate": string;
  "endDate": string;
  "description"?: string;
  "type"?: string;
  "discount": any;
  "autoApply"?: boolean;
  "singleUsePerUser"?: boolean;
  "forBirthdayMonthOnly"?: boolean;
  "isActive"?: boolean;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "status": number;
  "profilesAllowed"?: Array<any>;
  "tnc"?: string;
  "id"?: number;
  vendor?: Vendor;
  order?: Order[];
  couponRecord?: CouponRecord[];
}

export class Coupon implements CouponInterface {
  "vendorId": any;
  "name": string;
  "code": string;
  "minOrderTotal": number;
  "count": number;
  "redeemed": number;
  "used": number;
  "startDate": string;
  "endDate": string;
  "description": string;
  "type": string;
  "discount": any;
  "autoApply": boolean;
  "singleUsePerUser": boolean;
  "forBirthdayMonthOnly": boolean;
  "isActive": boolean;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "status": number;
  "profilesAllowed": Array<any>;
  "tnc": string;
  "id": number;
  vendor: Vendor;
  order: Order[];
  couponRecord: CouponRecord[];
  constructor(data?: CouponInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Coupon`.
   */
  public static getModelName() {
    return "Coupon";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Coupon for dynamic purposes.
  **/
  public static factory(data: CouponInterface): Coupon{
    return new Coupon(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Coupon',
      plural: 'coupons',
      path: 'coupons',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "minOrderTotal": {
          name: 'minOrderTotal',
          type: 'number',
          default: 0
        },
        "count": {
          name: 'count',
          type: 'number',
          default: 1
        },
        "redeemed": {
          name: 'redeemed',
          type: 'number',
          default: 0
        },
        "used": {
          name: 'used',
          type: 'number',
          default: 0
        },
        "startDate": {
          name: 'startDate',
          type: 'string'
        },
        "endDate": {
          name: 'endDate',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "discount": {
          name: 'discount',
          type: 'any'
        },
        "autoApply": {
          name: 'autoApply',
          type: 'boolean',
          default: false
        },
        "singleUsePerUser": {
          name: 'singleUsePerUser',
          type: 'boolean',
          default: true
        },
        "forBirthdayMonthOnly": {
          name: 'forBirthdayMonthOnly',
          type: 'boolean',
          default: false
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: false
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "profilesAllowed": {
          name: 'profilesAllowed',
          type: 'Array&lt;any&gt;'
        },
        "tnc": {
          name: 'tnc',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'couponId'
        },
        couponRecord: {
          name: 'couponRecord',
          type: 'CouponRecord[]',
          model: 'CouponRecord',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'couponId'
        },
      }
    }
  }
}

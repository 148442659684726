/* tslint:disable */
import {
  Vendor
} from '../index';

declare var Object: any;
export interface SupplierInterface {
  "businessName": string;
  "code"?: string;
  "gstNo"?: string;
  "vendorId"?: any;
  "status"?: number;
  "address"?: string;
  "phone"?: string;
  "contactPerson"?: string;
  "email"?: string;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: number;
  vendor?: Vendor;
}

export class Supplier implements SupplierInterface {
  "businessName": string;
  "code": string;
  "gstNo": string;
  "vendorId": any;
  "status": number;
  "address": string;
  "phone": string;
  "contactPerson": string;
  "email": string;
  "updatedAt": Date;
  "updatedBy": any;
  "id": number;
  vendor: Vendor;
  constructor(data?: SupplierInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Supplier`.
   */
  public static getModelName() {
    return "Supplier";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Supplier for dynamic purposes.
  **/
  public static factory(data: SupplierInterface): Supplier{
    return new Supplier(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Supplier',
      plural: 'suppliers',
      path: 'suppliers',
      idName: 'id',
      properties: {
        "businessName": {
          name: 'businessName',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "gstNo": {
          name: 'gstNo',
          type: 'string'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "address": {
          name: 'address',
          type: 'string'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "contactPerson": {
          name: 'contactPerson',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
      }
    }
  }
}

/* tslint:disable */
import {
  Profile,
  Order
} from '../index';

declare var Object: any;
export interface CheckoutSessionInterface {
  "profileId": any;
  "bookingId"?: string;
  "orderId"?: number;
  "shipOrder": number;
  "shippingDate": Date;
  "deliveryAddress"?: any;
  "acceptedTnc": boolean;
  "shippingQuote"?: any;
  "remarks"?: string;
  "paymentMode": string;
  "products": any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "status": number;
  "couponCode"?: string;
  "couponData"?: any;
  "id"?: any;
  profile?: Profile;
  order?: Order;
}

export class CheckoutSession implements CheckoutSessionInterface {
  "profileId": any;
  "bookingId": string;
  "orderId": number;
  "shipOrder": number;
  "shippingDate": Date;
  "deliveryAddress": any;
  "acceptedTnc": boolean;
  "shippingQuote": any;
  "remarks": string;
  "paymentMode": string;
  "products": any;
  "createdAt": Date;
  "updatedAt": Date;
  "status": number;
  "couponCode": string;
  "couponData": any;
  "id": any;
  profile: Profile;
  order: Order;
  constructor(data?: CheckoutSessionInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CheckoutSession`.
   */
  public static getModelName() {
    return "CheckoutSession";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CheckoutSession for dynamic purposes.
  **/
  public static factory(data: CheckoutSessionInterface): CheckoutSession{
    return new CheckoutSession(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CheckoutSession',
      plural: 'checkout-sessions',
      path: 'checkout-sessions',
      idName: 'id',
      properties: {
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "bookingId": {
          name: 'bookingId',
          type: 'string'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "shipOrder": {
          name: 'shipOrder',
          type: 'number',
          default: 0
        },
        "shippingDate": {
          name: 'shippingDate',
          type: 'Date'
        },
        "deliveryAddress": {
          name: 'deliveryAddress',
          type: 'any'
        },
        "acceptedTnc": {
          name: 'acceptedTnc',
          type: 'boolean',
          default: true
        },
        "shippingQuote": {
          name: 'shippingQuote',
          type: 'any'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "paymentMode": {
          name: 'paymentMode',
          type: 'string'
        },
        "products": {
          name: 'products',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "couponCode": {
          name: 'couponCode',
          type: 'string'
        },
        "couponData": {
          name: 'couponData',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
      }
    }
  }
}

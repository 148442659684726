/* tslint:disable */
import {
  Vendor,
  Product
} from '../index';

declare var Object: any;
export interface DiscountTierInterface {
  "vendorId"?: any;
  "name": string;
  "priceTier": Array<any>;
  "status"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "isActive": boolean;
  "id"?: any;
  vendor?: Vendor;
  product?: Product[];
}

export class DiscountTier implements DiscountTierInterface {
  "vendorId": any;
  "name": string;
  "priceTier": Array<any>;
  "status": number;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "isActive": boolean;
  "id": any;
  vendor: Vendor;
  product: Product[];
  constructor(data?: DiscountTierInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DiscountTier`.
   */
  public static getModelName() {
    return "DiscountTier";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of DiscountTier for dynamic purposes.
  **/
  public static factory(data: DiscountTierInterface): DiscountTier{
    return new DiscountTier(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'DiscountTier',
      plural: 'discountTiers',
      path: 'discountTiers',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "priceTier": {
          name: 'priceTier',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        product: {
          name: 'product',
          type: 'Product[]',
          model: 'Product',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'discountTierId'
        },
      }
    }
  }
}

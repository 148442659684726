<div class="row">
  <div class="col-lg-8">
    <div class="deznav">
      <div class="deznav-scroll" [perfectScrollbar]>
        <ul class="metismenu" id="menu" metis-menu>

          <li [ngClass]="{'mm-active': currentMenu === 'orders'}">
            <a [routerLink]="['/orders']" class="ai-icon" href="javascript:void(0);" (click)="currentMenu = 'orders'" aria-expanded="false">
              <i class="flaticon-381-layer-1"></i>
              <span class="nav-text">Orders</span>
            </a>
          </li>
          <li [ngClass]="{'mm-active': currentMenu === 'stock-audit'}">
            <a [routerLink]="['/inventory/audit']" class="ai-icon" href="javascript:void(0);" aria-expanded="false" (click)="currentMenu = 'stock-audit'">
              <i class="flaticon-022-copy"></i>
              <span class="nav-text">Stock Audit</span>
            </a>
          </li>
        </ul>

      </div>
    </div>

  </div>

  <div class="col-lg-4 deznav">

        <nav class="navbar navbar-expand">
          <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav header-right">
              <li class="nav-item header-profile">
                <a [routerLink]="['/login']" class="dropdown-item ai-icon" (click)="logout()">
                  <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18"
                       viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                       stroke-linejoin="round">
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  <span class="ml-2">Logout </span>
                </a>
              </li>
            </ul>

          </div>
        </nav>


  </div>
</div>




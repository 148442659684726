import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {AuthenticationService} from "../authentication.service";
import {LoggerService} from "../../shared/services/logger.service";
import {Profile} from '../../shared/sdk';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  returnUrl: any;
  public loginForm: FormGroup;
  public branchStaffs: Array<Profile> = [];
  private _unsubscribe: Subject<void>;
  appVersion: string = 'Unavailable';
  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private _authService: AuthenticationService,
              private spinner: NgxSpinnerService,
              public logger: LoggerService) {
  }

  ngOnInit(): void {
    this._unsubscribe = new Subject();
    this.appVersion = environment.appVersion;
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.createLoginForm();
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      password: ['', [Validators.minLength(6)]],
    });
  }

  async onSubmit(): Promise<any> {
    await this.spinner.show();
    try {
      console.log('logging in with email!');
      let credentials = this.loginForm.getRawValue();
      console.log('credentials', credentials);
      credentials.email = credentials.email.trim();
      credentials.password = credentials.password.trim();
      let response = await this._authService.signinWithFirebaseEmail(credentials.email, credentials.password);
      //await this.router.navigate(['/dashboard']);
      await this.spinner.hide();
      window.location.reload();
    } catch (e) {
      await this.spinner.hide();
      this.logger.displayMessage(e.message, 'Failed', 'error');
      return Promise.reject(e);
    }
  }

  formatBranchAddress(branch: any): string {
    if (!branch?.address) {
      return '';
    }
    return `<b>${branch.address.displayName}</b><br><b>Tel:</b> ${branch.address.phone}<br><b>Email:</b> ${branch.address.email}<br><b>Address: </b>${branch.address.addressLine1} ${branch.address.addressLine2}<br>${branch.address.street}<br>${branch.address.city}, ${branch.address.postCode}<br>${branch.address.state} Malaysia.`;
  }


  ngOnDestroy() {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}

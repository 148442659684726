/* tslint:disable */
import {
  Coupon,
  Profile,
  Order
} from '../index';

declare var Object: any;
export interface CouponRecordInterface {
  "profileId": any;
  "profileData": any;
  "couponId": number;
  "status"?: number;
  "createdAt"?: Date;
  "createdBy"?: any;
  "updatedAt"?: Date;
  "redeemedAt"?: Date;
  "updatedBy"?: any;
  "remarks"?: string;
  "orderId"?: number;
  "couponData"?: any;
  "id"?: number;
  coupon?: Coupon;
  profile?: Profile;
  order?: Order;
}

export class CouponRecord implements CouponRecordInterface {
  "profileId": any;
  "profileData": any;
  "couponId": number;
  "status": number;
  "createdAt": Date;
  "createdBy": any;
  "updatedAt": Date;
  "redeemedAt": Date;
  "updatedBy": any;
  "remarks": string;
  "orderId": number;
  "couponData": any;
  "id": number;
  coupon: Coupon;
  profile: Profile;
  order: Order;
  constructor(data?: CouponRecordInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CouponRecord`.
   */
  public static getModelName() {
    return "CouponRecord";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CouponRecord for dynamic purposes.
  **/
  public static factory(data: CouponRecordInterface): CouponRecord{
    return new CouponRecord(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CouponRecord',
      plural: 'couponRecords',
      path: 'couponRecords',
      idName: 'id',
      properties: {
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "profileData": {
          name: 'profileData',
          type: 'any'
        },
        "couponId": {
          name: 'couponId',
          type: 'number'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "redeemedAt": {
          name: 'redeemedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "couponData": {
          name: 'couponData',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        coupon: {
          name: 'coupon',
          type: 'Coupon',
          model: 'Coupon',
          relationType: 'belongsTo',
                  keyFrom: 'couponId',
          keyTo: 'id'
        },
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order',
          model: 'Order',
          relationType: 'belongsTo',
                  keyFrom: 'orderId',
          keyTo: 'id'
        },
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {BehaviorSubject} from 'rxjs';
import {ProfileApi, UserApi} from '../shared/sdk/services/custom';
import {LoggerService} from '../shared/services/logger.service';
import {first, take} from 'rxjs/operators';
import {MenuItem} from '../models/menu.model';
import {AngularFireAuth} from "@angular/fire/auth";
import {RealTime, User} from "../shared/sdk";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  lbLoginCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true); // deactivate when loggin in ::
  loggedInUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  myProfile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  myMessages: Array<{ sender: any; timeAgo: string; title: string; message: string; }> = [
    {
      sender: {displayName: 'Order System'},
      timeAgo: '3 minutes ago',
      title: 'New Order Places', message: 'Cathy Lu placed a new order = RM500'
    }
  ];

  myNotifications: Array<{ timeAgo: string; title: string; }> = [
    {
      timeAgo: '2 days ago',
      title: 'New System Update. v1.0 deployed'
    }
  ];

  myMenu: MenuItem[] = [
    {
      label: 'Main',
      isTitle: true
    },
    {
      label: 'Dashboard',
      icon: 'home',
      link: '/dashboard'
    },
    {
      label: 'Products',
      isTitle: true
    },
    {
      label: 'Collections',
      icon: 'users',
      link: '/products/collections',
    },
    {
      label: 'Categories',
      icon: 'users',
      link: '/products/categories',
    },
    {
      label: 'SubCategories',
      icon: 'users',
      link: '/products/subcategories',
    },
    {
      label: 'Products',
      icon: 'shopping-bag',
      link: '/products/products',
    },
    {
      label: 'AddOn Groups',
      icon: 'settings',
      link: '/products/add-on-groups',
    },
    {
      label: 'Sales Campaigns',
      icon: 'settings',
      link: '/products/sales-campaigns',
    },
    {
      label: 'Branches',
      isTitle: true
    },
    {
      label: 'Branches',
      icon: 'map-pin',
      link: '/branches',
    },
    {
      label: 'Terminals',
      icon: 'tablet',
      link: '/terminals',
    },
    {
      label: 'Printers',
      icon: 'printer',
      link: '/printers',
    },
    {
      label: 'Vouchers Config',
      isTitle: true
    },
    {
      label: 'Vouchers',
      icon: 'credit-card',
      link: '/vouchers',
    },
    {
      label: 'Users & Admins',
      isTitle: true
    },
    {
      label: 'Users',
      icon: 'users',
      link: '/profiles',
    },
    {
      label: 'Reports',
      isTitle: true
    },
    {
      label: 'Order Sales',
      icon: 'shopping-cart',
      link: '/reports/orders',
    },
    {
      label: 'Transactions',
      icon: 'dollar-sign',
      link: '/reports/transactions',
    },
    {
      label: 'Deliveries',
      icon: 'map',
      link: '/reports/deliveries',
    },
    {
      label: 'Blog',
      isTitle: true
    },
    {
      label: 'Feeds',
      icon: 'credit-card',
      link: '/feeds',
    },
    {
      label: 'Config',
      isTitle: true
    },
    {
      label: 'Settings',
      icon: 'settings',
      link: '/settings',
    }
  ];

  constructor(
    private _afAuth: AngularFireAuth,
    private router: Router,
    private _userApi: UserApi,
    private _profileApi: ProfileApi,
    private _logger: LoggerService,
    private realtimeService: RealTime,
    private spinner: NgxSpinnerService
  ) {

  }

  async checkUserAuthStatus(): Promise<any> { // run this to initialize the app!!!
    console.log('running checks');
    return new Promise<void>((resolve, reject) => {
      this._afAuth.authState.pipe()
        .subscribe(async user => {
          if (user && user.uid) {
            await this.initializeAuthentication();
            resolve();
          }
          resolve(); // this should directly redirect the user to login ::
        });
    });
  }

  async initializeAuthentication(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      // listen for profile ::
      this._afAuth.authState.subscribe(async user => {
        if (user && user.uid) {
          console.log('point 1');
          this.lbLoginCompleted.subscribe(async lbAuthState => { // check that loopback authentication is completed ::
            if (lbAuthState && this._userApi.isAuthenticated()) {
              console.log('point 2');
              this._logger.loadingText = 'securely logging you in ....';
              await this.spinner.show();
              try {
                let currentUser = {
                  //authData: this._userApi.getCurrentToken(),
                  firebaseUser: user,
                  firebaseToken: await user.getIdToken(),
                  user: await this._userApi.getCurrent({include: ['vendor', 'profile']}).toPromise(),
                  token: await this._userApi.getCurrentToken(),
                  profile: null
                };
                currentUser.profile = await this._profileApi.checkWalletBalance({userId: currentUser.user.id}).toPromise();
                if (!(currentUser.profile && currentUser.user?.vendor?.id)) {
                  // kick them out ::
                  this._logger.resetLoadingText();
                  await this.spinner.hide();
                  this.loggedInUser.next(null);
                  return this.logout();
                  resolve();
                }
                this._logger.resetLoadingText();
                await this.spinner.hide();
                this._logger.displayMessage('Welcome back, ' + currentUser.user.profile.displayName, 'Success', 'success');
                this.loggedInUser.next(currentUser);
                resolve();
              } catch (e) {
                this._logger.resetLoadingText();
                await this.spinner.hide();
                this._logger.displayMessage(e.message, 'Error', 'error');
                await this.logout();
                resolve();
              }
            }
          });
        }
      });
    });
  }

  async reloadProfile(): Promise<any> {
    let currentUser = this.loggedInUser.value;
    currentUser.profile = await this._profileApi.checkWalletBalance({userId: currentUser.user.id}).toPromise();
    this.loggedInUser.next(currentUser);
    return Promise.resolve(currentUser.profile);
  }

  async signinWithFirebaseEmail(email, password) {
    try {
      this.lbLoginCompleted.next(null);
      // check if this is a vendor first ::
      let check: any = await this._userApi.checkUserIsVendor({email: email}).toPromise();
      if (check?.code !== '00') {
        return Promise.reject(check.message);
      }
      let firebaseUser = await this._afAuth.signInWithEmailAndPassword(email, password);
      let authData = await this._userApi.login({email: email, password: firebaseUser.user.uid.toString()}).toPromise();
      //await sleep(2000);
      this.lbLoginCompleted.next(true);
      if (authData && authData.id) {
        let user: User = await this._userApi.getCurrent({include: ['profile', 'vendor']}).toPromise();
        /*this.loggedInUser.next({
          authData: authData,
          firebaseUser: firebaseUser,
          firebaseToken: await firebaseUser.user.getIdToken(),
          user: user,
          token: await this._userApi.getCurrentToken()
        });*/
        return Promise.resolve('Welcome back, ' + user.profile.displayName);
      }
      return Promise.reject('Account Login Failed');
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async loadCurrentUser(noOveride?: boolean): Promise<any> {
    // bypass because all will be loaded by the firebase block in the constructor ::
    if (!this._userApi.isAuthenticated()) {
      return Promise.reject('Not Logged In');
    }
    try {
      if (noOveride && this.loggedInUser.value?.user?.id) {
        return Promise.resolve(this.loggedInUser.value);
      }
      let user: User = await this._userApi.getCurrent({include: ['vendor']}).toPromise();
      this.spinner.hide();
      // set user to logged in state ::
      //this.loggedInUser.next({token: await this._userApi.getCurrentToken(), user: user});
      return Promise.resolve(this.loggedInUser.value);
    } catch (e) {
      this.spinner.hide();
      this._logger.displayMessage(e.message, 'Error', 'error');
      return Promise.reject(e);
    }
  }


  /*
    async register(credentials: any): Promise<any> {
      console.log('registering new user with email!');
      credentials.email = credentials.email.trim();
      credentials.password = credentials.password.trim();
      credentials.displayName = credentials.displayName.trim();
      this.spinner.show();
      try {

        let profile: Profile = await this._profileApi.create<any>({
          displayName: credentials.displayName,
          phone: '',
          notifications: {},
          userId: '',
          id: '',
          avatar: ''
        }).toPromise();
        credentials.profileId = profile.id;
        let user: User = await this._userApi.create(credentials).toPromise();
        let profileId = profile.id;
        delete (profile.id);
        profile = await this._profileApi.updateAttributes<Profile>(profileId, {...profile, userId: user.id}).toPromise();
        this.spinner.hide()
        // auto login the user ::
        return this.login(credentials);
      } catch (e) {
        this.spinner.hide();
        this._logger.displayMessage(e.message, 'Error', 'error');
      }
    }

    async login(credentials: any): Promise<any> {
      await this.spinner.show();
      this._logger.log('logging in with email!');
      credentials.email = credentials.email.trim();
      credentials.password = credentials.password.trim();
      try {
        let loggedInUser: any = await this._userApi.login(credentials).toPromise();
        // get the current User ::
        let vx = await this.loadCurrentUser();
        await this.spinner.hide();
        this._logger.displayMessage('Welcome back, ' + vx.user.profile.displayName);
        await this.router.navigate(['/dashboard']);
        return Promise.resolve(vx);
      } catch (e) {
        await this.spinner.hide();
        this._logger.displayMessage(e.message, 'Error', 'error');
        return Promise.reject(e);
      }
    }
   */
  async logout() {
    this._logger.log('auth', 'looging you out');
    try {
      this.lbLoginCompleted.next(null);
      let response = await this._afAuth.signOut();
      if (this._userApi.isAuthenticated()) { // only logout logged in user!
        await this._userApi.logout().toPromise();
      }
      this.loggedInUser.next(null);
      this._logger.displayMessage('See you soon. 👋 ', 'Success', 'success');
      await this.router.navigate(['auth/login']);
      return Promise.resolve(response);
    } catch (e) {
      await this.router.navigate(['auth/login']);
      return Promise.reject(e);
    }
  }

  avatarUrl: BehaviorSubject<string> = new BehaviorSubject<string>('assets/images/dashboard/designer.jpg');

  loadImage(size: string, image: any): string {
    let fileName = decodeURI(image.fileName).split('/').pop();
    image.url = decodeURI(image.url);
    return image.url.replace(fileName, `thumb@${size}_${fileName}`);
  }

}

export const environment = {
  appVersion: '1.0.1',
  production: true,
  firebase: {
    projectId: 'realme-18598',
    appId: '1:173915678854:web:76b174a0c1f529ada68907',
    storageBucket: 'realme-18598.appspot.com',
    locationId: 'asia-southeast1',
    apiKey: 'AIzaSyBcZBgNrqZ08QTOHshyg8tE-Lb0dSM0350',
    authDomain: 'realme-18598.firebaseapp.com',
    messagingSenderId: '173915678854',
    measurementId: 'G-4JCG2ZDXEQ',
  },
  loopback: {
    backendUrl: "https://realmeapi.kobotogether.com",
    apiUrl: "https://realmeapi.kobotogether.com/api",
    apiKey: "abc123!@#"
  },
};

/* tslint:disable */
import {
  Vendor,
  Order,
  User,
  Profile,
  Delivery,
  Transaction,
  ProductReview,
  Terminal,
  Printer,
  ProductInventory,
  Refund,
  GeoPoint
} from '../index';

declare var Object: any;
export interface BranchInterface {
  "vendorId": any;
  "isHQ"?: boolean;
  "code"?: string;
  "stockSheetName"?: string;
  "address": any;
  "description"?: string;
  "canDeliver"?: boolean;
  "deliveryPartner"?: string;
  "openOnPublicHolidays"?: boolean;
  "openOnWeekends"?: boolean;
  "offlineDates"?: Array<any>;
  "workingHours"?: any;
  "receiptHeader"?: string;
  "receiptFooter"?: string;
  "images"?: Array<any>;
  "doorToDoorService"?: boolean;
  "isActive"?: boolean;
  "status": number;
  "location"?: GeoPoint;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: any;
  vendor?: Vendor;
  order?: Order[];
  user?: User[];
  profile?: Profile[];
  delivery?: Delivery[];
  transaction?: Transaction[];
  productReview?: ProductReview[];
  terminal?: Terminal[];
  printer?: Printer[];
  productInventory?: ProductInventory[];
  refund?: Refund[];
}

export class Branch implements BranchInterface {
  "vendorId": any;
  "isHQ": boolean;
  "code": string;
  "stockSheetName": string;
  "address": any;
  "description": string;
  "canDeliver": boolean;
  "deliveryPartner": string;
  "openOnPublicHolidays": boolean;
  "openOnWeekends": boolean;
  "offlineDates": Array<any>;
  "workingHours": any;
  "receiptHeader": string;
  "receiptFooter": string;
  "images": Array<any>;
  "doorToDoorService": boolean;
  "isActive": boolean;
  "status": number;
  "location": GeoPoint;
  "updatedAt": Date;
  "updatedBy": any;
  "id": any;
  vendor: Vendor;
  order: Order[];
  user: User[];
  profile: Profile[];
  delivery: Delivery[];
  transaction: Transaction[];
  productReview: ProductReview[];
  terminal: Terminal[];
  printer: Printer[];
  productInventory: ProductInventory[];
  refund: Refund[];
  constructor(data?: BranchInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Branch`.
   */
  public static getModelName() {
    return "Branch";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Branch for dynamic purposes.
  **/
  public static factory(data: BranchInterface): Branch{
    return new Branch(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Branch',
      plural: 'branches',
      path: 'branches',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "isHQ": {
          name: 'isHQ',
          type: 'boolean',
          default: false
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "stockSheetName": {
          name: 'stockSheetName',
          type: 'string'
        },
        "address": {
          name: 'address',
          type: 'any'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "canDeliver": {
          name: 'canDeliver',
          type: 'boolean',
          default: true
        },
        "deliveryPartner": {
          name: 'deliveryPartner',
          type: 'string',
          default: 'lalamove'
        },
        "openOnPublicHolidays": {
          name: 'openOnPublicHolidays',
          type: 'boolean',
          default: true
        },
        "openOnWeekends": {
          name: 'openOnWeekends',
          type: 'boolean',
          default: true
        },
        "offlineDates": {
          name: 'offlineDates',
          type: 'Array&lt;any&gt;'
        },
        "workingHours": {
          name: 'workingHours',
          type: 'any'
        },
        "receiptHeader": {
          name: 'receiptHeader',
          type: 'string'
        },
        "receiptFooter": {
          name: 'receiptFooter',
          type: 'string'
        },
        "images": {
          name: 'images',
          type: 'Array&lt;any&gt;'
        },
        "doorToDoorService": {
          name: 'doorToDoorService',
          type: 'boolean',
          default: false
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "location": {
          name: 'location',
          type: 'GeoPoint'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        user: {
          name: 'user',
          type: 'User[]',
          model: 'User',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        profile: {
          name: 'profile',
          type: 'Profile[]',
          model: 'Profile',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        delivery: {
          name: 'delivery',
          type: 'Delivery[]',
          model: 'Delivery',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction[]',
          model: 'Transaction',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        productReview: {
          name: 'productReview',
          type: 'ProductReview[]',
          model: 'ProductReview',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        terminal: {
          name: 'terminal',
          type: 'Terminal[]',
          model: 'Terminal',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        printer: {
          name: 'printer',
          type: 'Printer[]',
          model: 'Printer',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        productInventory: {
          name: 'productInventory',
          type: 'ProductInventory[]',
          model: 'ProductInventory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
        refund: {
          name: 'refund',
          type: 'Refund[]',
          model: 'Refund',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'branchId'
        },
      }
    }
  }
}

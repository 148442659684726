/* tslint:disable */

declare var Object: any;
export interface OrderProductSerialInterface {
  "vendorId"?: string;
  "orderId"?: number;
  "productId"?: string;
  "serialNo": string;
  "productSeqNo"?: number;
  "productData"?: any;
  "profileData"?: any;
  "customerData"?: any;
  "spec"?: string;
  "color"?: string;
  "createdAt"?: Date;
  "orderedAt"?: Date;
  "soldAt"?: Date;
  "status"?: number;
  "remark"?: string;
  "createdBy": any;
  "id"?: number;
}

export class OrderProductSerial implements OrderProductSerialInterface {
  "vendorId": string;
  "orderId": number;
  "productId": string;
  "serialNo": string;
  "productSeqNo": number;
  "productData": any;
  "profileData": any;
  "customerData": any;
  "spec": string;
  "color": string;
  "createdAt": Date;
  "orderedAt": Date;
  "soldAt": Date;
  "status": number;
  "remark": string;
  "createdBy": any;
  "id": number;
  constructor(data?: OrderProductSerialInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `OrderProductSerial`.
   */
  public static getModelName() {
    return "OrderProductSerial";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of OrderProductSerial for dynamic purposes.
  **/
  public static factory(data: OrderProductSerialInterface): OrderProductSerial{
    return new OrderProductSerial(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'OrderProductSerial',
      plural: 'orderProductSerials',
      path: 'orderProductSerials',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'string'
        },
        "orderId": {
          name: 'orderId',
          type: 'number'
        },
        "productId": {
          name: 'productId',
          type: 'string'
        },
        "serialNo": {
          name: 'serialNo',
          type: 'string'
        },
        "productSeqNo": {
          name: 'productSeqNo',
          type: 'number'
        },
        "productData": {
          name: 'productData',
          type: 'any'
        },
        "profileData": {
          name: 'profileData',
          type: 'any'
        },
        "customerData": {
          name: 'customerData',
          type: 'any'
        },
        "spec": {
          name: 'spec',
          type: 'string'
        },
        "color": {
          name: 'color',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "orderedAt": {
          name: 'orderedAt',
          type: 'Date'
        },
        "soldAt": {
          name: 'soldAt',
          type: 'Date'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "remark": {
          name: 'remark',
          type: 'string'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}

<div class="authincation h-100">
  <div class="container vh-100">
    <div class="row justify-content-center h-100 align-items-center">
      <div class="col-lg-6 col-md-8">
        <div class="authincation-content">
          <div class="row no-gutters">
            <div class="col-xl-12">
              <div class="auth-form">
                <div class="text-center mb-3">
                  <a routerLink="." class="noble-ui-logo d-block mb-2"><img src="assets/images/logo.png" width="300px"></a>
                </div>
                <p class="small text-center">WebVersion: <span>{{appVersion}} (WH)</span></p>

                <div>
                  <h4 class="text-center mb-4">Sign in your account</h4>
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                      <label class="mb-1"><strong>Email</strong></label>
                      <input type="email" class="form-control" name="login[email]" formControlName="email">
                      <mat-error *ngIf="loginForm.hasError('required')">Please enter email</mat-error>
                    </div>

                    <!--<label class="mb-1"><strong>Email</strong></label>
                    <input type="email" class="form-control" name="login[email]" formControlName="email">-->

                    <div class="form-group">
                      <label class="mb-1"><strong>Password</strong></label>
                      <input type="password" class="form-control" name="login[password]" formControlName="password">
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block">Sign Me In</button>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

/* tslint:disable */
import {
  Vendor,
  Profile,
  Branch,
  Delivery,
  Transaction,
  ProductReview,
  DirectDeposit,
  Refund,
  Coupon,
  CouponRecord
} from '../index';

declare var Object: any;
export interface OrderInterface {
  "vendorId"?: any;
  "appVersion"?: string;
  "branchId"?: any;
  "checkoutSessionId": string;
  "createdAt"?: Date;
  "createdBy"?: any;
  "updatedAt"?: Date;
  "productSnapshots"?: Array<any>;
  "orderProducts"?: Array<any>;
  "profileId"?: any;
  "profileDetails"?: any;
  "paymentMode": string;
  "deliveryMode"?: string;
  "walletName"?: string;
  "isPickUp"?: boolean;
  "transactionId"?: number;
  "transactionIds"?: Array<any>;
  "remarks"?: string;
  "adminRemarks"?: string;
  "systemRemarks"?: string;
  "consignmentNoteNo"?: string;
  "deliveryOrderNo"?: string;
  "fulfillmentDate"?: Date;
  "preferredTime"?: string;
  "itemTotal": number;
  "amount": number;
  "totalDiscount"?: number;
  "taxAmount": number;
  "totalWithoutTax": number;
  "receivedOrderProducts"?: any;
  "status"?: number;
  "transactionStatus": number;
  "deliveryStatus"?: number;
  "isBackendOrder"?: boolean;
  "deliveryAddress"?: any;
  "branchAddress"?: any;
  "deliveryBooking"?: any;
  "deliveryRemarks"?: string;
  "deliveryId"?: number;
  "directDepositId"?: number;
  "deliveryQuote"?: any;
  "invoiceId"?: number;
  "storeId"?: number;
  "orderUpdates"?: Array<any>;
  "tracking"?: any;
  "snapshot"?: any;
  "couponCode"?: string;
  "couponId"?: number;
  "couponRecordId"?: number;
  "couponData"?: any;
  "couponSnapshot"?: any;
  "bookingId"?: string;
  "published"?: boolean;
  "dispatchImages"?: Array<any>;
  "id"?: number;
  vendor?: Vendor;
  profile?: Profile;
  branch?: Branch;
  delivery?: Delivery;
  transaction?: Transaction;
  productReview?: ProductReview[];
  directDeposit?: DirectDeposit;
  refund?: Refund[];
  coupon?: Coupon;
  couponRecord?: CouponRecord[];
}

export class Order implements OrderInterface {
  "vendorId": any;
  "appVersion": string;
  "branchId": any;
  "checkoutSessionId": string;
  "createdAt": Date;
  "createdBy": any;
  "updatedAt": Date;
  "productSnapshots": Array<any>;
  "orderProducts": Array<any>;
  "profileId": any;
  "profileDetails": any;
  "paymentMode": string;
  "deliveryMode": string;
  "walletName": string;
  "isPickUp": boolean;
  "transactionId": number;
  "transactionIds": Array<any>;
  "remarks": string;
  "adminRemarks": string;
  "systemRemarks": string;
  "consignmentNoteNo": string;
  "deliveryOrderNo": string;
  "fulfillmentDate": Date;
  "preferredTime": string;
  "itemTotal": number;
  "amount": number;
  "totalDiscount": number;
  "taxAmount": number;
  "totalWithoutTax": number;
  "receivedOrderProducts": any;
  "status": number;
  "transactionStatus": number;
  "deliveryStatus": number;
  "isBackendOrder": boolean;
  "deliveryAddress": any;
  "branchAddress": any;
  "deliveryBooking": any;
  "deliveryRemarks": string;
  "deliveryId": number;
  "directDepositId": number;
  "deliveryQuote": any;
  "invoiceId": number;
  "storeId": number;
  "orderUpdates": Array<any>;
  "tracking": any;
  "snapshot": any;
  "couponCode": string;
  "couponId": number;
  "couponRecordId": number;
  "couponData": any;
  "couponSnapshot": any;
  "bookingId": string;
  "published": boolean;
  "dispatchImages": Array<any>;
  "id": number;
  vendor: Vendor;
  profile: Profile;
  branch: Branch;
  delivery: Delivery;
  transaction: Transaction;
  productReview: ProductReview[];
  directDeposit: DirectDeposit;
  refund: Refund[];
  coupon: Coupon;
  couponRecord: CouponRecord[];
  constructor(data?: OrderInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Order`.
   */
  public static getModelName() {
    return "Order";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Order for dynamic purposes.
  **/
  public static factory(data: OrderInterface): Order{
    return new Order(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Order',
      plural: 'orders',
      path: 'orders',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "appVersion": {
          name: 'appVersion',
          type: 'string'
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "checkoutSessionId": {
          name: 'checkoutSessionId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "productSnapshots": {
          name: 'productSnapshots',
          type: 'Array&lt;any&gt;'
        },
        "orderProducts": {
          name: 'orderProducts',
          type: 'Array&lt;any&gt;'
        },
        "profileId": {
          name: 'profileId',
          type: 'any'
        },
        "profileDetails": {
          name: 'profileDetails',
          type: 'any'
        },
        "paymentMode": {
          name: 'paymentMode',
          type: 'string'
        },
        "deliveryMode": {
          name: 'deliveryMode',
          type: 'string',
          default: 'jtexpress'
        },
        "walletName": {
          name: 'walletName',
          type: 'string'
        },
        "isPickUp": {
          name: 'isPickUp',
          type: 'boolean',
          default: false
        },
        "transactionId": {
          name: 'transactionId',
          type: 'number'
        },
        "transactionIds": {
          name: 'transactionIds',
          type: 'Array&lt;any&gt;'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "adminRemarks": {
          name: 'adminRemarks',
          type: 'string'
        },
        "systemRemarks": {
          name: 'systemRemarks',
          type: 'string'
        },
        "consignmentNoteNo": {
          name: 'consignmentNoteNo',
          type: 'string'
        },
        "deliveryOrderNo": {
          name: 'deliveryOrderNo',
          type: 'string'
        },
        "fulfillmentDate": {
          name: 'fulfillmentDate',
          type: 'Date'
        },
        "preferredTime": {
          name: 'preferredTime',
          type: 'string',
          default: '12:00 to 14:00'
        },
        "itemTotal": {
          name: 'itemTotal',
          type: 'number',
          default: 0
        },
        "amount": {
          name: 'amount',
          type: 'number',
          default: 0
        },
        "totalDiscount": {
          name: 'totalDiscount',
          type: 'number',
          default: 0
        },
        "taxAmount": {
          name: 'taxAmount',
          type: 'number',
          default: 0
        },
        "totalWithoutTax": {
          name: 'totalWithoutTax',
          type: 'number',
          default: 0
        },
        "receivedOrderProducts": {
          name: 'receivedOrderProducts',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "transactionStatus": {
          name: 'transactionStatus',
          type: 'number',
          default: 0
        },
        "deliveryStatus": {
          name: 'deliveryStatus',
          type: 'number',
          default: 0
        },
        "isBackendOrder": {
          name: 'isBackendOrder',
          type: 'boolean',
          default: false
        },
        "deliveryAddress": {
          name: 'deliveryAddress',
          type: 'any'
        },
        "branchAddress": {
          name: 'branchAddress',
          type: 'any'
        },
        "deliveryBooking": {
          name: 'deliveryBooking',
          type: 'any'
        },
        "deliveryRemarks": {
          name: 'deliveryRemarks',
          type: 'string'
        },
        "deliveryId": {
          name: 'deliveryId',
          type: 'number'
        },
        "directDepositId": {
          name: 'directDepositId',
          type: 'number'
        },
        "deliveryQuote": {
          name: 'deliveryQuote',
          type: 'any'
        },
        "invoiceId": {
          name: 'invoiceId',
          type: 'number'
        },
        "storeId": {
          name: 'storeId',
          type: 'number'
        },
        "orderUpdates": {
          name: 'orderUpdates',
          type: 'Array&lt;any&gt;'
        },
        "tracking": {
          name: 'tracking',
          type: 'any',
          default: <any>null
        },
        "snapshot": {
          name: 'snapshot',
          type: 'any'
        },
        "couponCode": {
          name: 'couponCode',
          type: 'string'
        },
        "couponId": {
          name: 'couponId',
          type: 'number'
        },
        "couponRecordId": {
          name: 'couponRecordId',
          type: 'number'
        },
        "couponData": {
          name: 'couponData',
          type: 'any'
        },
        "couponSnapshot": {
          name: 'couponSnapshot',
          type: 'any'
        },
        "bookingId": {
          name: 'bookingId',
          type: 'string'
        },
        "published": {
          name: 'published',
          type: 'boolean',
          default: false
        },
        "dispatchImages": {
          name: 'dispatchImages',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        profile: {
          name: 'profile',
          type: 'Profile',
          model: 'Profile',
          relationType: 'belongsTo',
                  keyFrom: 'profileId',
          keyTo: 'id'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        delivery: {
          name: 'delivery',
          type: 'Delivery',
          model: 'Delivery',
          relationType: 'belongsTo',
                  keyFrom: 'deliveryId',
          keyTo: 'id'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction',
          model: 'Transaction',
          relationType: 'belongsTo',
                  keyFrom: 'transactionId',
          keyTo: 'id'
        },
        productReview: {
          name: 'productReview',
          type: 'ProductReview[]',
          model: 'ProductReview',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
        directDeposit: {
          name: 'directDeposit',
          type: 'DirectDeposit',
          model: 'DirectDeposit',
          relationType: 'belongsTo',
                  keyFrom: 'directDepositId',
          keyTo: 'id'
        },
        refund: {
          name: 'refund',
          type: 'Refund[]',
          model: 'Refund',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
        coupon: {
          name: 'coupon',
          type: 'Coupon',
          model: 'Coupon',
          relationType: 'belongsTo',
                  keyFrom: 'couponId',
          keyTo: 'id'
        },
        couponRecord: {
          name: 'couponRecord',
          type: 'CouponRecord[]',
          model: 'CouponRecord',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'orderId'
        },
      }
    }
  }
}

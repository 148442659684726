import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Config, ConfigApi, LoopBackConfig, Product, ProductApi, UserApi} from "../sdk";
import {AuthenticationService} from "../../authentication/authentication.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {LoggerService} from "./logger.service";


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  appConfig: Config;
  youtubeVideos: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  _appConfig: any;
  constructor(private http: HttpClient,
              private _userApi: UserApi,
              private _authService: AuthenticationService,
              private _productApi: ProductApi,
              private _configApi: ConfigApi,
              private _logger: LoggerService,
              private router: Router) {
    LoopBackConfig.setBaseURL(environment.loopback.backendUrl);
    LoopBackConfig.setApiVersion('api');
    this._authService.loggedInUser.subscribe(loggedInUser => {
      if (loggedInUser?.user?.vendorId) {
        /*this.youtubeService.getVideosForChanel(loggedInUser.user.vendor.youtubeChannelId, 15)
          .subscribe(res => {
            if (res) {
              console.log(res['items']);
              this.youtubeVideos.next(res['items']);
            }
          }) */
      }
    });
  }

  async initialize(): Promise<any> {

    return new Promise<any>(async (resolve, reject) => {
      Promise.all([
        this.checkAuthStatus(),
      ]).then(
        () => {
          resolve();
        }, reject);
    });
  }

  async checkAuthStatus(): Promise<any> {
    console.log('checkAuthStatus', 'started...' + this._userApi.isAuthenticated());
    // check loggedIn status ::
    if (this._userApi.isAuthenticated()) {
      console.log('checkAuthStatus', 'checking auth');
      try {
        //await this._userApi.getCurrent().toPromise();
        let au = await this._authService.checkUserAuthStatus();
        console.log('au has been returned!');
        return Promise.resolve(au);
      } catch (e) {
        console.log('checkAuthStatus', 'here we are')
        console.log('checkAuthStatus', 'status', e);
        if (e.statusCode === 401) {// is not authenticated! log them out::
          await this._userApi.logout().toPromise().catch(console.log);
          this.router.navigate(['auth/login']);
          return Promise.resolve();
        }
        return Promise.reject();
      }
    } else {
      console.log('checkAuthStatus', 'Not Authenticated!');
      //await this._userApi.logout().toPromise().catch(console.log);
      await this._authService.logout();
      //await this.router.navigate(['auth/login']);
      return Promise.resolve();
    }
  }

}

<!--**********************************
    Chat box start
***********************************-->
<div [ngClass]="{'': toggleChat, 'active': !toggleChat}" class=" chatbox ">
  <div class="chatbox-close" (click)="togglechatbar()"></div>
  <div class="custom-tab-1">


    <ul ngbNav #nav="ngbNav" (activeId)="3" class="nav nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Notes</a>
        <ng-template ngbNavContent>

          <div class="card mb-sm-3 mb-md-0 note_card">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/><rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/></g></svg></a>
              <div>
                <h6 class="mb-1">Notes</h6>
                <p class="mb-0">Add New Nots</p>
              </div>
              <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/><path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"/></g></svg></a>
            </div>
            <div class="card-body contacts_body p-0 pb-3"  [perfectScrollbar]>
              <ul class="contacts">
                <li class="active">
                  <div class="d-flex bd-highlight">
                    <div class="user_info">
                      <span>New order placed..</span>
                      <p>10 Aug 2020</p>
                    </div>
                    <div class="ml-auto">
                      <a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i class="fa fa-pencil"></i></a>
                      <a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight">
                    <div class="user_info">
                      <span>Youtube, a video-sharing website..</span>
                      <p>10 Aug 2020</p>
                    </div>
                    <div class="ml-auto">
                      <a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i class="fa fa-pencil"></i></a>
                      <a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight">
                    <div class="user_info">
                      <span>john just buy your product..</span>
                      <p>10 Aug 2020</p>
                    </div>
                    <div class="ml-auto">
                      <a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i class="fa fa-pencil"></i></a>
                      <a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight">
                    <div class="user_info">
                      <span>Athan Jacoby</span>
                      <p>10 Aug 2020</p>
                    </div>
                    <div class="ml-auto">
                      <a href="javascript:void(0)" class="btn btn-primary btn-xs sharp mr-1"><i class="fa fa-pencil"></i></a>
                      <a href="javascript:void(0)" class="btn btn-danger btn-xs sharp"><i class="fa fa-trash"></i></a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a  ngbNavLink>Alerts</a>
        <ng-template ngbNavContent>

          <div class="card mb-sm-3 mb-md-0 contacts_card">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg></a>
              <div>
                <h6 class="mb-1">Notications</h6>
                <p class="mb-0">Show All</p>
              </div>
              <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/><path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fill-rule="nonzero"/></g></svg></a>
            </div>
            <div class="card-body contacts_body p-0 pb-3" [perfectScrollbar]>
              <ul class="contacts">
                <li class="name-first-letter">SEVER STATUS</li>
                <li class="active">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont primary">KK</div>
                    <div class="user_info">
                      <span>David Nester Birthday</span>
                      <p class="text-primary">Today</p>
                    </div>
                  </div>
                </li>
                <li class="name-first-letter">SOCIAL</li>
                <li>
                  <div class="d-flex bd-highlight">
                    <div class="img_cont success">RU</div>
                    <div class="user_info">
                      <span>Perfection Simplified</span>
                      <p>Jame Smith commented on your status</p>
                    </div>
                  </div>
                </li>
                <li class="name-first-letter">SEVER STATUS</li>
                <li>
                  <div class="d-flex bd-highlight">
                    <div class="img_cont primary">AU</div>
                    <div class="user_info">
                      <span>AharlieKane</span>
                      <p>Sami is online</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="d-flex bd-highlight">
                    <div class="img_cont info">MO</div>
                    <div class="user_info">
                      <span>Athan Jacoby</span>
                      <p>Nargis left 30 mins ago</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="card-footer"></div>
          </div>

        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Chat</a>
        <ng-template ngbNavContent>

          <div class="card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box" [ngClass]="{'': toggleSingle, 'd-none': !toggleSingle}" >
            <div class=" card-header chat-list-header text-center">
              <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/><rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/></g></svg></a>
              <div>
                <h6 class="mb-1">Chat List</h6>
                <p class="mb-0">Show All</p>
              </div>
              <a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg></a>
            </div>
            <div id="DZ_W_Contacts_Body" class="card-body contacts_body p-0 pb-3"  [perfectScrollbar]>
              <ul class="contacts">
                <li class="name-first-letter">A</li>
                <li class="active dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>Archie Parker</span>
                      <p>Kalid is online</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Alfie Mason</span>
                      <p>Taherah left 7 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/3.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>AharlieKane</span>
                      <p>Sami is online</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/4.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Athan Jacoby</span>
                      <p>Nargis left 30 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="name-first-letter">B</li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/5.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Bashid Samim</span>
                      <p>Rashid left 50 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>Breddie Ronan</span>
                      <p>Kalid is online</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Ceorge Carson</span>
                      <p>Taherah left 7 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="name-first-letter">D</li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/3.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>Darry Parker</span>
                      <p>Sami is online</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/4.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Denry Hunter</span>
                      <p>Nargis left 30 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="name-first-letter">J</li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/5.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Jack Ronan</span>
                      <p>Rashid left 50 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>Jacob Tucker</span>
                      <p>Kalid is online</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>James Logan</span>
                      <p>Taherah left 7 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/3.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon"></span>
                    </div>
                    <div class="user_info">
                      <span>Joshua Weston</span>
                      <p>Sami is online</p>
                    </div>
                  </div>
                </li>
                <li class="name-first-letter">O</li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/4.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Oliver Acker</span>
                      <p>Nargis left 30 mins ago</p>
                    </div>
                  </div>
                </li>
                <li class="dz-chat-user" (click)= "singleChatWindow()">
                  <div class="d-flex bd-highlight">
                    <div class="img_cont">
                      <img src="assets/images/avatar/5.jpg" class="rounded-circle user_img" alt=""/>
                      <span class="online_icon offline"></span>
                    </div>
                    <div class="user_info">
                      <span>Oscar Weston</span>
                      <p>Rashid left 50 mins ago</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>


          <div class="card chat dz-chat-history-box d-none" [ngClass]="{'d-none': toggleSingle, '': !toggleSingle}">
            <div class="card-header chat-list-header text-center">
              <a href="javascript:void(0)" class="dz-chat-history-back"  (click)= "singleChatWindow()">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><polygon points="0 0 24 0 24 24 0 24"/><rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) " x="14" y="7" width="2" height="10" rx="1"/><path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) "/></g></svg>
              </a>
              <div>
                <h6 class="mb-1">Chat with Khelesh</h6>
                <p class="mb-0 text-success">Online</p>
              </div>
              <div class="dropdown dropdown-no-icon" ngbDropdown>
                <a href="javascript:void(0)" data-toggle="dropdown" ngbDropdownToggle><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"/><circle fill="#000000" cx="5" cy="12" r="2"/><circle fill="#000000" cx="12" cy="12" r="2"/><circle fill="#000000" cx="19" cy="12" r="2"/></g></svg></a>
                <ul class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                  <li class="dropdown-item"><i class="fa fa-user-circle text-primary mr-2"></i> View profile</li>
                  <li class="dropdown-item"><i class="fa fa-users text-primary mr-2"></i> Add to close friends</li>
                  <li class="dropdown-item"><i class="fa fa-plus text-primary mr-2"></i> Add to group</li>
                  <li class="dropdown-item"><i class="fa fa-ban text-primary mr-2"></i> Block</li>
                </ul>
              </div>
            </div>
            <div class="card-body msg_card_body" [perfectScrollbar]>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  Hi, how are you samim?
                  <span class="msg_time">8:40 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Hi Khalid i am good tnx how about you?
                  <span class="msg_time_send">8:55 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  I am good too, thank you for your chat template
                  <span class="msg_time">9:00 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  You are welcome
                  <span class="msg_time_send">9:05 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  I am looking for your next templates
                  <span class="msg_time">9:07 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Ok, thank you have a good day
                  <span class="msg_time_send">9:10 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  Bye, see you
                  <span class="msg_time">9:12 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  Hi, how are you samim?
                  <span class="msg_time">8:40 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Hi Khalid i am good tnx how about you?
                  <span class="msg_time_send">8:55 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  I am good too, thank you for your chat template
                  <span class="msg_time">9:00 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  You are welcome
                  <span class="msg_time_send">9:05 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  I am looking for your next templates
                  <span class="msg_time">9:07 AM, Today</span>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-4">
                <div class="msg_cotainer_send">
                  Ok, thank you have a good day
                  <span class="msg_time_send">9:10 AM, Today</span>
                </div>
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/2.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
              </div>
              <div class="d-flex justify-content-start mb-4">
                <div class="img_cont_msg">
                  <img src="assets/images/avatar/1.jpg" class="rounded-circle user_img_msg" alt=""/>
                </div>
                <div class="msg_cotainer">
                  Bye, see you
                  <span class="msg_time">9:12 AM, Today</span>
                </div>
              </div>
            </div>
            <div class="card-footer type_msg">
              <div class="input-group">
                <textarea class="form-control" placeholder="Type your message..."></textarea>
                <div class="input-group-append">
                  <button type="button" class="btn btn-primary"><i class="fa fa-location-arrow"></i></button>
                </div>
              </div>
            </div>
          </div>


        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

  </div>
</div>
<!--**********************************
    Chat box End
***********************************-->



<div class="header">
  <div class="header-content">
    <nav class="navbar navbar-expand">
      <div class="collapse navbar-collapse justify-content-between">
        <div class="header-left">
          <div class="dashboard_bar">
            Dashboard
          </div>
        </div>

        <ul class="navbar-nav header-right">
          <li class="nav-item dropdown notification_dropdown  dropdown-no-icon" ngbDropdown>
            <a class="nav-link  ai-icon" href="javascript:void(0);" role="button" data-toggle="dropdown" ngbDropdownToggle>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1.16663C11.6793 1.16663 9.45374 2.0885 7.8128 3.72944C6.17186 5.37039 5.24999 7.59598 5.24999 9.91663V16.7416L3.31332 19.6583C3.08119 20.0162 2.94953 20.4299 2.9321 20.8562C2.91468 21.2824 3.01213 21.7055 3.21427 22.0811C3.41641 22.4568 3.71581 22.7712 4.08112 22.9915C4.44644 23.2118 4.86424 23.3298 5.29082 23.3333H22.7091C23.1385 23.3319 23.5594 23.2143 23.9274 22.9932C24.2954 22.772 24.5967 22.4554 24.7993 22.0769C25.002 21.6984 25.0986 21.2722 25.0787 20.8433C25.0589 20.4145 24.9234 19.9989 24.6866 19.6408L22.75 16.7416V9.91663C22.75 7.59598 21.8281 5.37039 20.1872 3.72944C18.5462 2.0885 16.3206 1.16663 14 1.16663Z" fill="#636363"/>
                <path d="M14 26.8333C14.7231 26.8325 15.4282 26.6077 16.0184 26.1899C16.6085 25.772 17.0548 25.1817 17.2958 24.4999H10.7042C10.9452 25.1817 11.3915 25.772 11.9816 26.1899C12.5718 26.6077 13.2769 26.8325 14 26.8333Z" fill="#636363"/>
              </svg>
              <div class="pulse-css"></div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <div id="DZ_W_Notification1" class="widget-media dz-scroll p-3 height380" [perfectScrollbar]>
                <ul class="timeline">
                  <li>
                    <div class="timeline-panel">
                      <div class="media mr-2">
                        <img alt="image" width="50" src="assets/images/avatar/1.jpg">
                      </div>
                      <div class="media-body">
                        <h6 class="mb-1">Dr sultads Send you Photo</h6>
                        <small class="d-block">29 July 2020 - 02:26 PM</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-panel">
                      <div class="media mr-2 media-info">
                        KG
                      </div>
                      <div class="media-body">
                        <h6 class="mb-1">Resport created successfully</h6>
                        <small class="d-block">29 July 2020 - 02:26 PM</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-panel">
                      <div class="media mr-2 media-success">
                        <i class="fa fa-home"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="mb-1">Reminder : Treatment Time!</h6>
                        <small class="d-block">29 July 2020 - 02:26 PM</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-panel">
                      <div class="media mr-2">
                        <img alt="image" width="50" src="assets/images/avatar/1.jpg">
                      </div>
                      <div class="media-body">
                        <h6 class="mb-1">Dr sultads Send you Photo</h6>
                        <small class="d-block">29 July 2020 - 02:26 PM</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-panel">
                      <div class="media mr-2 media-danger">
                        KG
                      </div>
                      <div class="media-body">
                        <h6 class="mb-1">Resport created successfully</h6>
                        <small class="d-block">29 July 2020 - 02:26 PM</small>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="timeline-panel">
                      <div class="media mr-2 media-primary">
                        <i class="fa fa-home"></i>
                      </div>
                      <div class="media-body">
                        <h6 class="mb-1">Reminder : Treatment Time!</h6>
                        <small class="d-block">29 July 2020 - 02:26 PM</small>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <a class="all-notification" href="javascript:void(0);">See all notifications <i class="ti-arrow-right"></i></a>
            </div>
          </li>
          <li class="nav-item dropdown notification_dropdown" (click)="togglechatbar()">
            <a class="nav-link bell bell-link" href="javascript:void(0);">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.9166 0.166626H3.08332C2.30977 0.166626 1.56791 0.473917 1.02093 1.0209C0.473947 1.56788 0.166656 2.30974 0.166656 3.08329V18.25C0.166656 19.0235 0.473947 19.7654 1.02093 20.3124C1.56791 20.8593 2.30977 21.1666 3.08332 21.1666H4.24999V25.25C4.25 25.3512 4.27637 25.4507 4.32648 25.5386C4.3766 25.6266 4.44874 25.7 4.53582 25.7516C4.6245 25.8028 4.72509 25.8298 4.82749 25.8298C4.92988 25.8298 5.03048 25.8028 5.11915 25.7516L13.7291 21.1666H22.9166C23.6902 21.1666 24.4321 20.8593 24.979 20.3124C25.526 19.7654 25.8333 19.0235 25.8333 18.25V3.08329C25.8333 2.30974 25.526 1.56788 24.979 1.0209C24.4321 0.473917 23.6902 0.166626 22.9166 0.166626ZM21.75 15.3333H4.24999C4.09528 15.3333 3.9469 15.2718 3.83751 15.1624C3.72811 15.053 3.66665 14.9047 3.66665 14.75C3.66665 14.5952 3.72811 14.4469 3.83751 14.3375C3.9469 14.2281 4.09528 14.1666 4.24999 14.1666H21.75C21.9047 14.1666 22.0531 14.2281 22.1625 14.3375C22.2719 14.4469 22.3333 14.5952 22.3333 14.75C22.3333 14.9047 22.2719 15.053 22.1625 15.1624C22.0531 15.2718 21.9047 15.3333 21.75 15.3333ZM21.75 11.25H4.24999C4.09528 11.25 3.9469 11.1885 3.83751 11.0791C3.72811 10.9697 3.66665 10.8213 3.66665 10.6666C3.66665 10.5119 3.72811 10.3635 3.83751 10.2541C3.9469 10.1447 4.09528 10.0833 4.24999 10.0833H21.75C21.9047 10.0833 22.0531 10.1447 22.1625 10.2541C22.2719 10.3635 22.3333 10.5119 22.3333 10.6666C22.3333 10.8213 22.2719 10.9697 22.1625 11.0791C22.0531 11.1885 21.9047 11.25 21.75 11.25ZM21.75 7.16663H4.24999C4.09528 7.16663 3.9469 7.10517 3.83751 6.99577C3.72811 6.88637 3.66665 6.738 3.66665 6.58329C3.66665 6.42858 3.72811 6.28021 3.83751 6.17081C3.9469 6.06142 4.09528 5.99996 4.24999 5.99996H21.75C21.9047 5.99996 22.0531 6.06142 22.1625 6.17081C22.2719 6.28021 22.3333 6.42858 22.3333 6.58329C22.3333 6.738 22.2719 6.88637 22.1625 6.99577C22.0531 7.10517 21.9047 7.16663 21.75 7.16663Z" fill="#636363"/>
              </svg>
              <div class="pulse-css"></div>
            </a>
          </li>
          <li class="nav-item dropdown notification_dropdown dropdown-no-icon" ngbDropdown>
            <a class="nav-link" href="javascript:void(0);" data-toggle="dropdown" ngbDropdownToggle>
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.515 24.8942C11.2013 25.4995 12.0849 25.8336 13 25.8336C13.9151 25.8336 14.7987 25.4995 15.485 24.8942C18.4892 22.2517 25.5125 15.2751 25.8334 7.68005C25.9373 5.80349 25.2965 3.96171 24.0502 2.55489C22.804 1.14807 21.0529 0.289858 19.1775 0.16672C17.964 0.10879 16.7563 0.367679 15.6732 0.917932C14.5901 1.46819 13.6688 2.29086 13 3.30505C12.3342 2.28795 11.4135 1.46302 10.3296 0.912401C9.24579 0.361783 8.03662 0.10469 6.82252 0.16672C4.94711 0.289858 3.19606 1.14807 1.9498 2.55489C0.703537 3.96171 0.0627602 5.80349 0.166687 7.68005C0.48752 15.2751 7.51085 22.2517 10.515 24.8942Z" fill="#636363"/>
              </svg>
              <div class="pulse-css"></div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <div id="DZ_W_TimeLine02" class="widget-timeline dz-scroll style-1 ps ps--active-y p-3 height370">
                <ul class="timeline">
                  <li>
                    <div class="timeline-badge primary"></div>
                    <a class="timeline-panel text-muted" href="javascript:void(0);">
                      <span>10 minutes ago</span>
                      <h6 class="mb-0">Youtube, a video-sharing website, goes live <strong class="text-primary">$500</strong>.</h6>
                    </a>
                  </li>
                  <li>
                    <div class="timeline-badge info">
                    </div>
                    <a class="timeline-panel text-muted" href="javascript:void(0);">
                      <span>20 minutes ago</span>
                      <h6 class="mb-0">New order placed <strong class="text-info">#XF-2356.</strong></h6>
                      <p class="mb-0">Quisque a consequat ante Sit amet magna at volutapt...</p>
                    </a>
                  </li>
                  <li>
                    <div class="timeline-badge danger">
                    </div>
                    <a class="timeline-panel text-muted" href="javascript:void(0);">
                      <span>30 minutes ago</span>
                      <h6 class="mb-0">john just buy your product <strong class="text-warning">Sell $250</strong></h6>
                    </a>
                  </li>
                  <li>
                    <div class="timeline-badge success">
                    </div>
                    <a class="timeline-panel text-muted" href="javascript:void(0);">
                      <span>15 minutes ago</span>
                      <h6 class="mb-0">StumbleUpon is acquired by eBay. </h6>
                    </a>
                  </li>
                  <li>
                    <div class="timeline-badge warning">
                    </div>
                    <a class="timeline-panel text-muted" href="javascript:void(0);">
                      <span>20 minutes ago</span>
                      <h6 class="mb-0">Mashable, a news website and blog, goes live.</h6>
                    </a>
                  </li>
                  <li>
                    <div class="timeline-badge dark">
                    </div>
                    <a class="timeline-panel text-muted" href="javascript:void(0);">
                      <span>20 minutes ago</span>
                      <h6 class="mb-0">Mashable, a news website and blog, goes live.</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown header-profile dropdown-no-icon" ngbDropdown>
            <a class="nav-link" href="javascript:void(0);" role="button" data-toggle="dropdown" ngbDropdownToggle>
              <img src="assets/images/profile/pic1.jpg" width="20" alt=""/>
              <div class="header-info">
                <span class="fs-20 font-w500">{{loggedInUser.value?.user.displayName}}</span>
                <small>Branch Admin</small>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <!--<a [routerLink]="['/admin/app-profile']" class="dropdown-item ai-icon">
                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                <span class="ml-2">Profile </span>
              </a>
              <a [routerLink]="['/admin/email-inbox']" class="dropdown-item ai-icon">
                <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" class="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                <span class="ml-2">Inbox </span>
              </a> -->
              <a [routerLink]="['/login']" class="dropdown-item ai-icon">
                <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                <span class="ml-2">Logout </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

/* tslint:disable */
import {
  Vendor,
  Stock,
  Store
} from '../index';

declare var Object: any;
export interface StockMovementInterface {
  "stockId": number;
  "stockCode": string;
  "stockName": string;
  "storeId": number;
  "storeCode": string;
  "storeName": string;
  "tmnHwIdInt": number;
  "tmnTxnId": number;
  "operationId": number;
  "quantity": number;
  "combinedBalance"?: number;
  "balance": number;
  "staffId": number;
  "staffCard": string;
  "updatedBy"?: any;
  "tmnTxnTime": Date;
  "remarks"?: string;
  "bookingData"?: any;
  "isBooking"?: any;
  "itemRemark"?: string;
  "vendorId": any;
  "updatedAt": Date;
  "documentNo"?: string;
  "reference1"?: string;
  "reference2"?: string;
  "id"?: number;
  vendor?: Vendor;
  stock?: Stock;
  store?: Store;
}

export class StockMovement implements StockMovementInterface {
  "stockId": number;
  "stockCode": string;
  "stockName": string;
  "storeId": number;
  "storeCode": string;
  "storeName": string;
  "tmnHwIdInt": number;
  "tmnTxnId": number;
  "operationId": number;
  "quantity": number;
  "combinedBalance": number;
  "balance": number;
  "staffId": number;
  "staffCard": string;
  "updatedBy": any;
  "tmnTxnTime": Date;
  "remarks": string;
  "bookingData": any;
  "isBooking": any;
  "itemRemark": string;
  "vendorId": any;
  "updatedAt": Date;
  "documentNo": string;
  "reference1": string;
  "reference2": string;
  "id": number;
  vendor: Vendor;
  stock: Stock;
  store: Store;
  constructor(data?: StockMovementInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `StockMovement`.
   */
  public static getModelName() {
    return "StockMovement";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of StockMovement for dynamic purposes.
  **/
  public static factory(data: StockMovementInterface): StockMovement{
    return new StockMovement(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'StockMovement',
      plural: 'stockMovement',
      path: 'stockMovement',
      idName: 'id',
      properties: {
        "stockId": {
          name: 'stockId',
          type: 'number'
        },
        "stockCode": {
          name: 'stockCode',
          type: 'string'
        },
        "stockName": {
          name: 'stockName',
          type: 'string'
        },
        "storeId": {
          name: 'storeId',
          type: 'number'
        },
        "storeCode": {
          name: 'storeCode',
          type: 'string'
        },
        "storeName": {
          name: 'storeName',
          type: 'string'
        },
        "tmnHwIdInt": {
          name: 'tmnHwIdInt',
          type: 'number'
        },
        "tmnTxnId": {
          name: 'tmnTxnId',
          type: 'number'
        },
        "operationId": {
          name: 'operationId',
          type: 'number',
          default: 1
        },
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "combinedBalance": {
          name: 'combinedBalance',
          type: 'number'
        },
        "balance": {
          name: 'balance',
          type: 'number'
        },
        "staffId": {
          name: 'staffId',
          type: 'number'
        },
        "staffCard": {
          name: 'staffCard',
          type: 'string'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "tmnTxnTime": {
          name: 'tmnTxnTime',
          type: 'Date'
        },
        "remarks": {
          name: 'remarks',
          type: 'string'
        },
        "bookingData": {
          name: 'bookingData',
          type: 'any'
        },
        "isBooking": {
          name: 'isBooking',
          type: 'any',
          default: <any>null
        },
        "itemRemark": {
          name: 'itemRemark',
          type: 'string'
        },
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "documentNo": {
          name: 'documentNo',
          type: 'string'
        },
        "reference1": {
          name: 'reference1',
          type: 'string'
        },
        "reference2": {
          name: 'reference2',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        stock: {
          name: 'stock',
          type: 'Stock',
          model: 'Stock',
          relationType: 'belongsTo',
                  keyFrom: 'stockId',
          keyTo: 'id'
        },
        store: {
          name: 'store',
          type: 'Store',
          model: 'Store',
          relationType: 'belongsTo',
                  keyFrom: 'storeId',
          keyTo: 'id'
        },
      }
    }
  }
}

/* tslint:disable */
import {
  Vendor,
  Category,
  Subcategory,
  AddOnGroup,
  ProductReview,
  ProductInventory,
  DiscountTier
} from '../index';

declare var Object: any;
export interface ProductInterface {
  "vendorId": any;
  "name": string;
  "code": string;
  "rating"?: any;
  "alternateName"?: string;
  "uom"?: string;
  "requireCarDelivery"?: boolean;
  "leadTime"?: string;
  "ordering"?: number;
  "tags"?: Array<any>;
  "keywords"?: Array<any>;
  "attributes"?: Array<any>;
  "attributeStock"?: Array<any>;
  "addOns"?: Array<any>;
  "slug"?: string;
  "relatedProducts"?: Array<any>;
  "details"?: string;
  "description"?: string;
  "invoiceDescription"?: string;
  "shortDescription"?: string;
  "isFeatured"?: boolean;
  "chefsPick"?: boolean;
  "isHot"?: boolean;
  "price"?: number;
  "customPrice"?: any;
  "quantity"?: number;
  "discountTierId"?: any;
  "rrp": number;
  "salePrice"?: number;
  "isOutOfStock"?: boolean;
  "isComingSoon"?: boolean;
  "releaseDate"?: string;
  "inventory"?: number;
  "isActive"?: boolean;
  "favoriteCount"?: number;
  "likesCount"?: number;
  "isSale"?: boolean;
  "images"?: Array<any>;
  "promotionalMaterial"?: Array<any>;
  "media"?: Array<any>;
  "collections"?: Array<any>;
  "unitBonus"?: number;
  "videos"?: Array<any>;
  "youtubeVideoId"?: string;
  "subcategoryId": any;
  "orderLimit"?: number;
  "categoryId": any;
  "deliveryDetails"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "createdBy"?: any;
  "updatedBy"?: any;
  "status": number;
  "canBeComplimentaryItem"?: boolean;
  "isComplimentaryItem"?: boolean;
  "id"?: any;
  "addOnGroupId"?: any;
  vendor?: Vendor;
  category?: Category;
  subcategory?: Subcategory;
  addOnGroup?: AddOnGroup;
  productReview?: ProductReview[];
  productInventory?: ProductInventory[];
  discountTier?: DiscountTier;
}

export class Product implements ProductInterface {
  "vendorId": any;
  "name": string;
  "code": string;
  "rating": any;
  "alternateName": string;
  "uom": string;
  "requireCarDelivery": boolean;
  "leadTime": string;
  "ordering": number;
  "tags": Array<any>;
  "keywords": Array<any>;
  "attributes": Array<any>;
  "attributeStock": Array<any>;
  "addOns": Array<any>;
  "slug": string;
  "relatedProducts": Array<any>;
  "details": string;
  "description": string;
  "invoiceDescription": string;
  "shortDescription": string;
  "isFeatured": boolean;
  "chefsPick": boolean;
  "isHot": boolean;
  "price": number;
  "customPrice": any;
  "quantity": number;
  "discountTierId": any;
  "rrp": number;
  "salePrice": number;
  "isOutOfStock": boolean;
  "isComingSoon": boolean;
  "releaseDate": string;
  "inventory": number;
  "isActive": boolean;
  "favoriteCount": number;
  "likesCount": number;
  "isSale": boolean;
  "images": Array<any>;
  "promotionalMaterial": Array<any>;
  "media": Array<any>;
  "collections": Array<any>;
  "unitBonus": number;
  "videos": Array<any>;
  "youtubeVideoId": string;
  "subcategoryId": any;
  "orderLimit": number;
  "categoryId": any;
  "deliveryDetails": any;
  "createdAt": Date;
  "updatedAt": Date;
  "createdBy": any;
  "updatedBy": any;
  "status": number;
  "canBeComplimentaryItem": boolean;
  "isComplimentaryItem": boolean;
  "id": any;
  "addOnGroupId": any;
  vendor: Vendor;
  category: Category;
  subcategory: Subcategory;
  addOnGroup: AddOnGroup;
  productReview: ProductReview[];
  productInventory: ProductInventory[];
  discountTier: DiscountTier;
  constructor(data?: ProductInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Product`.
   */
  public static getModelName() {
    return "Product";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Product for dynamic purposes.
  **/
  public static factory(data: ProductInterface): Product{
    return new Product(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Product',
      plural: 'products',
      path: 'products',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "code": {
          name: 'code',
          type: 'string'
        },
        "rating": {
          name: 'rating',
          type: 'any'
        },
        "alternateName": {
          name: 'alternateName',
          type: 'string'
        },
        "uom": {
          name: 'uom',
          type: 'string'
        },
        "requireCarDelivery": {
          name: 'requireCarDelivery',
          type: 'boolean',
          default: false
        },
        "leadTime": {
          name: 'leadTime',
          type: 'string',
          default: 'None'
        },
        "ordering": {
          name: 'ordering',
          type: 'number',
          default: 1000
        },
        "tags": {
          name: 'tags',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "keywords": {
          name: 'keywords',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "attributes": {
          name: 'attributes',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "attributeStock": {
          name: 'attributeStock',
          type: 'Array&lt;any&gt;'
        },
        "addOns": {
          name: 'addOns',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "slug": {
          name: 'slug',
          type: 'string'
        },
        "relatedProducts": {
          name: 'relatedProducts',
          type: 'Array&lt;any&gt;'
        },
        "details": {
          name: 'details',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "invoiceDescription": {
          name: 'invoiceDescription',
          type: 'string'
        },
        "shortDescription": {
          name: 'shortDescription',
          type: 'string'
        },
        "isFeatured": {
          name: 'isFeatured',
          type: 'boolean',
          default: false
        },
        "chefsPick": {
          name: 'chefsPick',
          type: 'boolean',
          default: false
        },
        "isHot": {
          name: 'isHot',
          type: 'boolean',
          default: false
        },
        "price": {
          name: 'price',
          type: 'number',
          default: 0
        },
        "customPrice": {
          name: 'customPrice',
          type: 'any'
        },
        "quantity": {
          name: 'quantity',
          type: 'number'
        },
        "discountTierId": {
          name: 'discountTierId',
          type: 'any'
        },
        "rrp": {
          name: 'rrp',
          type: 'number',
          default: 0
        },
        "salePrice": {
          name: 'salePrice',
          type: 'number',
          default: 0
        },
        "isOutOfStock": {
          name: 'isOutOfStock',
          type: 'boolean',
          default: false
        },
        "isComingSoon": {
          name: 'isComingSoon',
          type: 'boolean',
          default: false
        },
        "releaseDate": {
          name: 'releaseDate',
          type: 'string'
        },
        "inventory": {
          name: 'inventory',
          type: 'number',
          default: 0
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean',
          default: true
        },
        "favoriteCount": {
          name: 'favoriteCount',
          type: 'number',
          default: 0
        },
        "likesCount": {
          name: 'likesCount',
          type: 'number',
          default: 0
        },
        "isSale": {
          name: 'isSale',
          type: 'boolean',
          default: false
        },
        "images": {
          name: 'images',
          type: 'Array&lt;any&gt;'
        },
        "promotionalMaterial": {
          name: 'promotionalMaterial',
          type: 'Array&lt;any&gt;'
        },
        "media": {
          name: 'media',
          type: 'Array&lt;any&gt;'
        },
        "collections": {
          name: 'collections',
          type: 'Array&lt;any&gt;'
        },
        "unitBonus": {
          name: 'unitBonus',
          type: 'number',
          default: 0
        },
        "videos": {
          name: 'videos',
          type: 'Array&lt;any&gt;'
        },
        "youtubeVideoId": {
          name: 'youtubeVideoId',
          type: 'string'
        },
        "subcategoryId": {
          name: 'subcategoryId',
          type: 'any'
        },
        "orderLimit": {
          name: 'orderLimit',
          type: 'number',
          default: 10000
        },
        "categoryId": {
          name: 'categoryId',
          type: 'any'
        },
        "deliveryDetails": {
          name: 'deliveryDetails',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "createdBy": {
          name: 'createdBy',
          type: 'any'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "canBeComplimentaryItem": {
          name: 'canBeComplimentaryItem',
          type: 'boolean',
          default: false
        },
        "isComplimentaryItem": {
          name: 'isComplimentaryItem',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "addOnGroupId": {
          name: 'addOnGroupId',
          type: 'any'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
        category: {
          name: 'category',
          type: 'Category',
          model: 'Category',
          relationType: 'belongsTo',
                  keyFrom: 'categoryId',
          keyTo: 'id'
        },
        subcategory: {
          name: 'subcategory',
          type: 'Subcategory',
          model: 'Subcategory',
          relationType: 'belongsTo',
                  keyFrom: 'subcategoryId',
          keyTo: 'id'
        },
        addOnGroup: {
          name: 'addOnGroup',
          type: 'AddOnGroup',
          model: 'AddOnGroup',
          relationType: 'belongsTo',
                  keyFrom: 'addOnGroupId',
          keyTo: 'id'
        },
        productReview: {
          name: 'productReview',
          type: 'ProductReview[]',
          model: 'ProductReview',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'productId'
        },
        productInventory: {
          name: 'productInventory',
          type: 'ProductInventory[]',
          model: 'ProductInventory',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'productId'
        },
        discountTier: {
          name: 'discountTier',
          type: 'DiscountTier',
          model: 'DiscountTier',
          relationType: 'belongsTo',
                  keyFrom: 'discountTierId',
          keyTo: 'id'
        },
      }
    }
  }
}

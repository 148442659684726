import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from '../../authentication/authentication.service';
import {isPlatformBrowser} from '@angular/common';
import {NgxSpinner, NgxSpinnerService} from 'ngx-spinner';
import {LoggerService} from '../services/logger.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  currentMenu: string = 'dashboard';
  tableOrPrinterControls: string = 'tablet';
  toggleChat: boolean = true;

  loggedInUser: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private authService: AuthenticationService, private spinner: NgxSpinnerService, private logger: LoggerService) {
    this.authService.loggedInUser
      .subscribe(loggedInUser => {
        if (loggedInUser) {
          this.loggedInUser.next(loggedInUser);
        }
      });
  }

  ngOnInit(): void {

  }

  toggleIcon: boolean = true;

  toggleLoveIcon() {
    this.toggleIcon = !this.toggleIcon;
  }

  activeId: number = 1;

  togglechatbar(activeId?: number) {
    this.activeId = activeId || 1;
    this.toggleChat = !this.toggleChat;

  }

  logout(): void {
    this.authService.logout();
  }


}

import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from "@angular/common/http";
import {AuthenticationModule} from "./authentication/authentication.module";
import {SDKBrowserModule} from "./shared/sdk";
import {ToastrModule} from "ngx-toastr";
import {AppRoutingModule} from "./app-routing.module";
import {NgbActiveModal, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {NgxSpinnerModule} from "ngx-spinner";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {MatListModule} from "@angular/material/list";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatCardModule} from "@angular/material/card";
import {MatButtonModule} from "@angular/material/button";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MetismenuAngularModule} from "@metismenu/angular";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatMenuModule} from "@angular/material/menu";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDividerModule} from "@angular/material/divider";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDialogModule} from "@angular/material/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatRadioModule} from "@angular/material/radio";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSliderModule} from "@angular/material/slider";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSortModule} from "@angular/material/sort";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTreeModule} from "@angular/material/tree";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {FooterComponent} from "./shared/footer/footer.component";
import {HeaderComponent} from "./shared/header/header.component";
import {BaseComponent} from "./base/base.component";
import {NavHeaderComponent} from "./shared/nav-header/nav-header.component";
import {NavigationComponent} from "./shared/navigation/navigation.component";
import {ToastsContainer} from "./shared/services/toast/toasts-container.component";
import {environment} from "../environments/environment";
import firebase from "firebase";
import initializeApp = firebase.initializeApp;
import {AngularFireStorageModule} from "@angular/fire/storage";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from "@angular/fire/analytics";
import {AngularFireModule} from "@angular/fire";
import {ConfigService} from "./shared/services/config.service";
import {ImageService} from "./shared/services/image/image.service";
import {DataService} from "./shared/services/data/data.service";
import {ToastService} from "./shared/services/toast/toast.service";
import {FirestoreService} from "./shared/services/firestore/firestore.service";
import {LoggerService} from "./shared/services/logger.service";
import {SharedService} from "./shared.service";
import {ErrorHandlerService} from "./shared/services/error-handler/error-handler.service";
import {LightgalleryModule} from "lightgallery/angular";

export function initProviderFactory(provider: ConfigService) {
  return () => provider.initialize();
}

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    FooterComponent,
    NavHeaderComponent,
    NavigationComponent,
    HeaderComponent,
    ToastsContainer
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PerfectScrollbarModule,
    NgbModule,
    HttpClientModule,
    AuthenticationModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireStorageModule, // storage
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    SDKBrowserModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    SweetAlert2Module.forRoot(),
    NgxSpinnerModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MetismenuAngularModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTabsModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatRadioModule,
    DragDropModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    //MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule
  ],
  providers: [
    ScreenTrackingService, UserTrackingService,
    ImageService,
    DataService,
    //NgbActiveModal,
    ToastService,
    FirestoreService,
    ConfigService,
    SharedService,
    LoggerService,
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {provide: APP_INITIALIZER, useFactory: initProviderFactory, deps: [ConfigService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

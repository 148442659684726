import {Component, OnInit, ViewChild} from '@angular/core';
import {SharedService} from '../shared.service';
import {DataService} from '../shared/services/data/data.service';
import {EventQueueService} from '../shared/services/event-queue/event-queue.service';
import {AppEventType} from '../shared/services/event-queue/app.event.type';
import {switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnInit {
  navSidebarClass: boolean = true;
  hamburgerClass: boolean = false;

  constructor(public sharedService: SharedService, public dataService: DataService, private eventQueue: EventQueueService) {

  }



  ngOnInit(): void {

  }

}

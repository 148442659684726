/* tslint:disable */
import {
  Vendor
} from '../index';

declare var Object: any;
export interface ComboCampaignInterface {
  "vendorId"?: any;
  "name": string;
  "description"?: string;
  "status"?: number;
  "isActive"?: boolean;
  "comboProducts"?: Array<any>;
  "complimentaryProducts"?: Array<any>;
  "startDate"?: any;
  "endDate"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "updatedBy"?: any;
  "id"?: number;
  vendor?: Vendor;
}

export class ComboCampaign implements ComboCampaignInterface {
  "vendorId": any;
  "name": string;
  "description": string;
  "status": number;
  "isActive": boolean;
  "comboProducts": Array<any>;
  "complimentaryProducts": Array<any>;
  "startDate": any;
  "endDate": any;
  "createdAt": Date;
  "updatedAt": Date;
  "updatedBy": any;
  "id": number;
  vendor: Vendor;
  constructor(data?: ComboCampaignInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `ComboCampaign`.
   */
  public static getModelName() {
    return "ComboCampaign";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of ComboCampaign for dynamic purposes.
  **/
  public static factory(data: ComboCampaignInterface): ComboCampaign{
    return new ComboCampaign(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'ComboCampaign',
      plural: 'comboCampaigns',
      path: 'comboCampaigns',
      idName: 'id',
      properties: {
        "vendorId": {
          name: 'vendorId',
          type: 'any'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "isActive": {
          name: 'isActive',
          type: 'boolean'
        },
        "comboProducts": {
          name: 'comboProducts',
          type: 'Array&lt;any&gt;'
        },
        "complimentaryProducts": {
          name: 'complimentaryProducts',
          type: 'Array&lt;any&gt;'
        },
        "startDate": {
          name: 'startDate',
          type: 'any'
        },
        "endDate": {
          name: 'endDate',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "updatedBy": {
          name: 'updatedBy',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
        vendor: {
          name: 'vendor',
          type: 'Vendor',
          model: 'Vendor',
          relationType: 'belongsTo',
                  keyFrom: 'vendorId',
          keyTo: 'id'
        },
      }
    }
  }
}

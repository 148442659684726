/* tslint:disable */
import {
  User,
  Order,
  Branch,
  Delivery,
  Transaction,
  ProductReview,
  DirectDeposit,
  UserGroup,
  Refund,
  Store,
  CouponRecord,
  GeoPoint
} from '../index';

declare var Object: any;
export interface ProfileInterface {
  "displayName": string;
  "email"?: string;
  "plainEmail"?: string;
  "userId"?: any;
  "userGroupId"?: any;
  "phone"?: string;
  "dob"?: string;
  "ic"?: string;
  "status"?: number;
  "avatar"?: any;
  "shopVerified"?: boolean;
  "isBusinessUser"?: boolean;
  "businessData"?: any;
  "mailchimpId"?: string;
  "deviceToken"?: string;
  "favorites"?: Array<any>;
  "deliveryAddresses"?: Array<any>;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "notifications"?: any;
  "storeId"?: number;
  "uid"?: string;
  "tokens"?: Array<any>;
  "location"?: GeoPoint;
  "rights"?: any;
  "walletClientId"?: number;
  "savingsAccountId"?: number;
  "walletBalance"?: number;
  "branchId"?: any;
  "id"?: any;
  user?: User;
  order?: Order[];
  branch?: Branch;
  delivery?: Delivery[];
  transaction?: Transaction[];
  productReview?: ProductReview[];
  directDeposit?: DirectDeposit[];
  userGroup?: UserGroup;
  refund?: Refund[];
  store?: Store;
  couponRecord?: CouponRecord[];
}

export class Profile implements ProfileInterface {
  "displayName": string;
  "email": string;
  "plainEmail": string;
  "userId": any;
  "userGroupId": any;
  "phone": string;
  "dob": string;
  "ic": string;
  "status": number;
  "avatar": any;
  "shopVerified": boolean;
  "isBusinessUser": boolean;
  "businessData": any;
  "mailchimpId": string;
  "deviceToken": string;
  "favorites": Array<any>;
  "deliveryAddresses": Array<any>;
  "createdAt": Date;
  "updatedAt": Date;
  "notifications": any;
  "storeId": number;
  "uid": string;
  "tokens": Array<any>;
  "location": GeoPoint;
  "rights": any;
  "walletClientId": number;
  "savingsAccountId": number;
  "walletBalance": number;
  "branchId": any;
  "id": any;
  user: User;
  order: Order[];
  branch: Branch;
  delivery: Delivery[];
  transaction: Transaction[];
  productReview: ProductReview[];
  directDeposit: DirectDeposit[];
  userGroup: UserGroup;
  refund: Refund[];
  store: Store;
  couponRecord: CouponRecord[];
  constructor(data?: ProfileInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Profile`.
   */
  public static getModelName() {
    return "Profile";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Profile for dynamic purposes.
  **/
  public static factory(data: ProfileInterface): Profile{
    return new Profile(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Profile',
      plural: 'profiles',
      path: 'profiles',
      idName: 'id',
      properties: {
        "displayName": {
          name: 'displayName',
          type: 'string'
        },
        "email": {
          name: 'email',
          type: 'string'
        },
        "plainEmail": {
          name: 'plainEmail',
          type: 'string'
        },
        "userId": {
          name: 'userId',
          type: 'any'
        },
        "userGroupId": {
          name: 'userGroupId',
          type: 'any'
        },
        "phone": {
          name: 'phone',
          type: 'string'
        },
        "dob": {
          name: 'dob',
          type: 'string'
        },
        "ic": {
          name: 'ic',
          type: 'string'
        },
        "status": {
          name: 'status',
          type: 'number',
          default: 0
        },
        "avatar": {
          name: 'avatar',
          type: 'any'
        },
        "shopVerified": {
          name: 'shopVerified',
          type: 'boolean'
        },
        "isBusinessUser": {
          name: 'isBusinessUser',
          type: 'boolean'
        },
        "businessData": {
          name: 'businessData',
          type: 'any'
        },
        "mailchimpId": {
          name: 'mailchimpId',
          type: 'string'
        },
        "deviceToken": {
          name: 'deviceToken',
          type: 'string'
        },
        "favorites": {
          name: 'favorites',
          type: 'Array&lt;any&gt;'
        },
        "deliveryAddresses": {
          name: 'deliveryAddresses',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "notifications": {
          name: 'notifications',
          type: 'any',
          default: <any>null
        },
        "storeId": {
          name: 'storeId',
          type: 'number'
        },
        "uid": {
          name: 'uid',
          type: 'string'
        },
        "tokens": {
          name: 'tokens',
          type: 'Array&lt;any&gt;'
        },
        "location": {
          name: 'location',
          type: 'GeoPoint'
        },
        "rights": {
          name: 'rights',
          type: 'any',
          default: <any>null
        },
        "walletClientId": {
          name: 'walletClientId',
          type: 'number'
        },
        "savingsAccountId": {
          name: 'savingsAccountId',
          type: 'number'
        },
        "walletBalance": {
          name: 'walletBalance',
          type: 'number',
          default: 0
        },
        "branchId": {
          name: 'branchId',
          type: 'any'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        order: {
          name: 'order',
          type: 'Order[]',
          model: 'Order',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
        branch: {
          name: 'branch',
          type: 'Branch',
          model: 'Branch',
          relationType: 'belongsTo',
                  keyFrom: 'branchId',
          keyTo: 'id'
        },
        delivery: {
          name: 'delivery',
          type: 'Delivery[]',
          model: 'Delivery',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
        transaction: {
          name: 'transaction',
          type: 'Transaction[]',
          model: 'Transaction',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
        productReview: {
          name: 'productReview',
          type: 'ProductReview[]',
          model: 'ProductReview',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
        directDeposit: {
          name: 'directDeposit',
          type: 'DirectDeposit[]',
          model: 'DirectDeposit',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
        userGroup: {
          name: 'userGroup',
          type: 'UserGroup',
          model: 'UserGroup',
          relationType: 'belongsTo',
                  keyFrom: 'userGroupId',
          keyTo: 'id'
        },
        refund: {
          name: 'refund',
          type: 'Refund[]',
          model: 'Refund',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
        store: {
          name: 'store',
          type: 'Store',
          model: 'Store',
          relationType: 'belongsTo',
                  keyFrom: 'storeId',
          keyTo: 'id'
        },
        couponRecord: {
          name: 'couponRecord',
          type: 'CouponRecord[]',
          model: 'CouponRecord',
          relationType: 'hasMany',
                  keyFrom: 'id',
          keyTo: 'profileId'
        },
      }
    }
  }
}
